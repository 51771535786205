<template>
  <div class="main">
    
    <b-container class="container">
  
    <vue-custom-scrollbar class="scroll-area" :style="'width: '+nochatSize+'%;'" :settings="settings" @ps-scroll-y="handleScroll" ref="scroll">
    <div class="yt" @click="ytPlay" :style="'height: '+blindHeight+'vh'"></div>
    <video-player  class="video-js vjs-theme-fantasy vjs-big-play-centered video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @playing="onPlayerPlaying($event)"
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 @ended="onPlayerEnded($event)"
                 :controls="false"
                 :children="[]"
                 v-if="isReady"
                 >
     
    </video-player>
   
    <div class="empty" :style="'height: '+responsiveHeight+'vh'" v-if='!isReady'>
      <div class="empty-text">
        Kolejka jest pusta
      </div>
    </div>
    <b-container class="chatResponsive" v-if="chatActive && chatResponsive==true" fluid>
      
          <iframe id="twitch-chat-embed"
                :src="parentChat"
                height="100%"
                width="100%"
                >
          </iframe>
        
    </b-container>
    <b-container class="options" fluid>
      <b-row class="options-row">

        <b-col cols="6" class="options-col">

          <b-button class="b-login" @click="TwitchLogin" v-if="this.user==null"><img src="../assets/logottv.png" class="ttv-logo"/></b-button>
          <b-button class="b-logout" @click="logout" v-if="this.user!=null">WYLOGUJ</b-button>
        
        </b-col>

        <b-col class="options-col">
          <b-button class="b-hidestream" @click="hideStream" v-if="streamActive">WYŁĄCZ STREAM</b-button>
          <b-button class="b-hidestream" @click="hideStream" v-else>WŁĄCZ STREAM</b-button>
        </b-col>
        <b-col class="options-col">
          <b-button class="b-hidechat" @click="hideChat" v-if="chatActive">WYŁĄCZ CZAT</b-button>
          <b-button class="b-hidechat" @click="hideChat" v-else>WŁĄCZ CZAT</b-button>
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
      
      <b-row  class="aq">
        <b-col sm>
          <b-form @submit.prevent='addMovie(link360,link480,link720,link1080)' class="add-form" v-if="admins.includes(this.user)">
          
            <b-form-input
              v-model="link360"
              placeholder="Link do jakości 360p / link do Youtube"
              class="addMovie"
            >
            </b-form-input>
            
             <b-form-input
              v-model="link480"
              placeholder="Link do jakości 480p"
              class="addMovie"
            >
            </b-form-input>
            
             <b-form-input
              v-model="link720"
              placeholder="Link do jakości 720p"
              class="addMovie"
            >
            </b-form-input>
            
            <b-form-input
              v-model="link1080"
              placeholder="Link do jakości 1080p"
              class="addMovie"
            >
            </b-form-input>
            <b-form-input
              v-model="movieTitle"
              placeholder="Tytuł filmu *opcjonalne"
              class="addMovieTitle"
            >
            </b-form-input>
            <b-form-input
              v-model="movieThumb"
              placeholder="Miniaturka filmu (Link do obrazka) *opcjonalne"
              class="addMovieThumb"
            >
            </b-form-input>
            <b-button type="submit" class="b-add" >DODAJ</b-button>
            <b-form-select class="s-add" v-model="selected" :options="options"></b-form-select>
            
          </b-form>
        </b-col>
        
        <b-col class="Queue-col" sm>
          <vue-custom-scrollbar class="scrollQueue" :settings="settings">
          <b-list-group  class="queueList" v-for="(movie, index) in queueList" v-bind:key="index">
            <b-list-group-item class="queueGroup" v-if="!queueEmpty">
              <!--<a href="" target="_blank" rel="noopener noreferrer" class="gotolink">-->
              <div class="queueItem" v-if="thumbnail.length>0">
                <b-col cols="3">
                  <img v-if="(thumbnail[index].length!=0)" :src="thumbnail[index]" alt="" class="queueThumb"/>
                  <img v-else src="https://cdn.7tv.app/emote/60b22e1261df920001670c74/4x.webp" alt="" class="queueThumb"/>
                  
                </b-col>
                <b-col cols="7">
                  
                  <div v-if="(title[index].length!=0)" class="queueTitle">{{title[index]}}</div>
                  <div v-else class="queueTitle">Film {{(index+1)}}</div>
                 
                </b-col>
                <b-col cols="2" v-if="admins.includes(user)">
                  <div class="icon-trash" style="float: left;" @click="deleteOne(index)">
                    <div class="trash-lid"></div>
                    <div class="trash-container"></div>
                    <div class="trash-line-1"></div>
                    <div class="trash-line-2"></div>
                    <div class="trash-line-3"></div>
                  </div>
                </b-col>
             
              </div>
            <!--</a>-->
            </b-list-group-item>
          </b-list-group>

    
          <b-button @click="clearQueue" class="b-clear" v-if="admins.includes(user) && queueEmpty==false">WYCZYŚĆ KOLEJKE</b-button>
        </vue-custom-scrollbar>
        </b-col>
    
      </b-row>
    </b-container>
    <footer class="footer">
          <div class="madeby">Made by: Gutknecht</div>&nbsp;
          <a href="https://www.twitch.tv/Gutknecht" target="_blank" rel="noopener noreferrer" class="a-link"><img src="../assets/twitch-mark.webp" alt="" class="logos"></a>&nbsp;
          <a href="https://github.com/Gutknecht123" target="_blank" rel="noopener noreferrer" class="a-link"><img src="../assets/github-mark-white.webp" alt="" class="logos"></a>
      </footer>
    <div class="TwitchStream-container" :style="'width: '+nochatSize+'%;'">
        <vue-resizable
                v-if="streamActive"
                :active="handlers"
                :height="strHeight"
                :width="strWidth"
                :max-width="maxW"
                :max-height="maxH"
                :min-width="minW"
                :min-height="minH"
                :left="left"
                :top="top"
                :fit-parent="true"
                :dragSelector="dragSelector"
                @mount="eHandler"
                @resize:move="eHandler2"
                @resize:start="eHandler2"
                @resize:end="eHandler"
                @drag:move="eHandler2"
                @drag:start="eHandler2"
                @drag:end="eHandler"
                class="TwitchStream"
              
            
          >
        
          <iframe class="iframestream"
              :src="parentStream"
              :height="strHeight"
              :width="strWidth"

              >
              
          </iframe>
        
        <div class="resize-container" v-if="focused"></div>
        <div class="drag-container-2"></div>

        </vue-resizable>
      </div>

  <div class="TwitchChat-container" v-if="chatActive && chatResponsive==false" :style="'width: '+100+'%;'">
        <vue-resizable
                
                :active="handlersChat"
                :width="chatWidth"
                :min-width="chatMinWidth"
                :max-width="chatMaxWidth"
                :fit-parent="true"
                :height="chatH"
                :top="0"
                :left="chatL"
            
                @mount="chatResize"
                @resize:move="chatResize2"
                @resize:start="chatResize2"
                @resize:end="chatResize"
                class="TwitchChat"


        >
        <iframe id="twitch-chat-embed"
            :src="parentChat"
            height="100%"
            :width="chatWidth"
            
            >
        </iframe>
        <div class="resize-container" v-if="focused"></div>

        </vue-resizable>
  </div>

    </vue-custom-scrollbar>

    </b-container>
       
    
  </div>
</template>

<script>

import VideoService from '../VideoService';
import store from '../store/store'
import { mapStores } from 'pinia';
import VueResizable from 'vue-resizable'
import vueCustomScrollbar from 'vue-custom-scrollbar'

import 'video.js/dist/video-js.min.css';
import '../assets/fantasy/index.css';

//import VueVideoThumbnail from 'vue-video-thumbnail'
export default {
  name: 'Video',
  components: {
     VueResizable,
     vueCustomScrollbar,
     //VueVideoThumbnail
    },
  data() {
    return {
      playerOptions: {
          muted: true,
          controls: true,
          techOrder: ['html5','youtube'],
          fill: true,
          autoplay: false,
          controlBar: {
        
            remainingTimeDisplay: true,
            
          },
          
          plugins: {
          videoJsResolutionSwitcher: {
            default: 'high',
            dynamicLabel: false,
            ui: true
            }
          }
      },
      options: [
        {value: null, text: 'Wybierz format'},
        {value: 'mp4', text: 'MP4'},
        {value: 'youtube', text: 'YOUTUBE'},
      ],
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
        scrollingThreshold: 0
      },
      cols: 12,
      connection: null,
      arr: [],
      current: null,
      channel: 'Gutknecht',
      token: null,
      user: null,
      link360: "",
      link480: "",
      link720: "",
      link1080: "",
      movies: [],
      queueList: [],
      isReady: false,
      parentChat: 'https://www.twitch.tv/embed/'+process.env.VUE_APP_CHANNEL+'/chat?darkpopout&parent='+process.env.VUE_APP_PARENT,
      parentStream: 'https://player.twitch.tv/?channel='+process.env.VUE_APP_CHANNEL+'&parent='+process.env.VUE_APP_PARENT+'&muted=true&autoplay=true',
      admins: process.env.VUE_APP_ADMINS,
      selected: null,
      streamActive: true,
      chatActive: true,
      strWidth: window.innerWidth * 0.2,
      strHeight: (window.innerWidth * 0.2)/(16/9),
      chatWidth: window.innerWidth * 0.2,
      chatMinWidth: window.innerWidth * 0.175,
      chatMaxWidth: window.innerWidth * 0.25,
      chatH: 100+"%",
      chatL: "",
      handlers: ["r", "b", "l", "t"],
      handlersChat: ["l"],
      left: 0,
      top: 0,
      maxW: window.innerWidth * 0.5,
      maxH: window.innerHeight * 0.5,
      minW: window.innerWidth * 0.15,
      minH: window.innerHeight * 0.175,
      dragSelector: ".drag-container-1, .drag-container-2",
      nochatSize: 82.5,
      usersCount: 0,
      focused: false,
      rWidth: '100%',
      rHeight: '100%',
      thumbnail: [],
      title: [],
      movieThumb: "",
      movieTitle: "",
      queueEmpty: true,
      chatResponsive: false,
      responsiveHeight: 100,
      timeHelp: 0,
      scrolling: false,
      yt: false,
      canPause: false,
      blindHeight: 100,
      overControl: false
      
    }
  },
  async created() {
    if(window.innerHeight > window.innerWidth){
      this.chatResponsive = true
      this.nochatSize = 100
    }else{
      this.chatResponsive = false
      this.nochatSize = ((window.innerWidth-this.chatWidth)/window.innerWidth)*100
    }
    this.rWidth = this.strWidth
    this.rHeight = this.strHeight
    
    this.chatH = window.innerHeight
    this.chatL = window.innerWidth * (this.nochatSize/100)
    window.addEventListener("resize", this.sizeCorrect);
    
    if(this.$cookies.isKey("sActive")){
      if(this.$cookies.get("sActive")=="true"){
        this.streamActive = true
      }else{
        this.streamActive = false
      }
    }

    if(this.$cookies.isKey("cActive")){
      if(this.$cookies.get("cActive")=="true"){
        this.chatActive = true
      }else{
        this.chatActive = false
        this.sizeCorrect()
      }
    }

    if(this.tokenStore.token){

      this.user = await VideoService.TwitchLogin(this.tokenStore.token)
      
    }

    const tempmovies = await VideoService.getMovies()
    
    //console.log(tempmovies.data[0].movies[0])

    if(tempmovies.data.length!=0){
      this.queueList = tempmovies.data
      console.log("to movies")
      console.log(this.queueList[0].movies[0].type)
 
      for(var i = 0; i<this.queueList.length; i++){
      this.thumbnail.push(this.queueList[i].thumbnail)
      this.title.push(this.queueList[i].title)

      }
      console.log(this.queueList[0].thumbnail)
      this.movies = tempmovies.data[0].movies
      this.isReady=true
      this.queueEmpty = false
    }else{
      this.isReady = false
      this.queueEmpty = true
    }

    
  
  },
  sockets: {

    connection(){
      console.log('socket connected')
      
    },
    /*
    titlechange(data){
      this.usersCount = data
      document.title = "Gutmovies ("+this.usersCount+")"
    },
    */
    queueSend(queue){

      this.queueList = queue
      console.log("działa")
      console.log(this.queueList)
      this.thumbnail = []
      this.title = []
      for(var i = 0; i<this.queueList.length; i++){
      this.thumbnail.push(this.queueList[i].thumbnail)
      this.title.push(this.queueList[i].title)
      }

    },

    playertime(data){
      console.log('To data'+data)
      
      if(!this.admins.includes(this.user)){
        this.current = data
        this.$refs.videoPlayer.player.currentTime(this.current)
      }
    },
    delayupdate(data){
      
      if(!this.admins.includes(this.user)){
        if(data.isPaused == false){
          this.canPause = true
        }else{
          this.canPause = false
        }
        if(this.overControl){
          this.blindHeight = 50
        }else{
          this.blindHeight = 97
        }
        if((data.time - this.$refs.videoPlayer.player.currentTime()) < -3||(data.time - this.$refs.videoPlayer.player.currentTime()) > 3){
          this.current = data.time
          this.$refs.videoPlayer.player.currentTime(this.current)
          //console.log(this.$refs.videoPlayer.player.currentTime())
          if(this.$refs.videoPlayer.player.currentTime()==0){
            this.canPause = false
          }
        }
      }else{
        if(this.overControl){
          this.blindHeight = 50
        }else{
          this.blindHeight = 97
        }
      }
      console.log(this.canPause)
    },
    playerpause(){

      if(!this.admins.includes(this.user)){
        this.$refs.videoPlayer.player.pause()
        this.canPause = false
      }
    },
    playerplay(){
      if(!this.admins.includes(this.user)){
        this.$refs.videoPlayer.player.play()
        this.canPause = true
        this.blindHeight = 97
      }
    },
    updateonend(queue){

        console.log(queue)
        if(queue.length>0){
          
          this.movies = queue[0].movies
          this.queueList = queue
          this.thumbnail = []
          this.title = []
          this.canPause = false
          for(var i = 0; i<this.queueList.length; i++){
            this.thumbnail.push(this.queueList[i].thumbnail)
            this.title.push(this.queueList[i].title)
          }

          this.$refs.videoPlayer.player.playlist(this.$refs.videoPlayer.player.updateSrc(this.movies))

          this.$refs.videoPlayer.player.playlist.first()

          this.$refs.videoPlayer.player.playlist.autoadvance(0);
          
          console.log(this.canPause)
        }else{
          this.isReady = false
          this.yt = false
          this.movies = []
      
        }
    },
    updatequeue(queue){
        
        if(queue.length>0){

          this.isReady = true

          this.movies = queue[0].movies
          this.queueList = queue
          this.thumbnail = []
          this.title = []
          
          for(var i = 0; i<this.queueList.length; i++){
            this.thumbnail.push(this.queueList[i].thumbnail)
            this.title.push(this.queueList[i].title)
          }
          this.queueEmpty = false
          
          this.$refs.videoPlayer.player.playlist(this.$refs.videoPlayer.player.updateSrc(this.movies))
          this.$refs.videoPlayer.player.playlist.autoadvance(0);
          
        }else{
          this.isReady = false
          this.queueEmpty = true
          this.thumbnail = []
          this.movies = []
          this.title = []
          this.yt = false
        }
      
    }
  },
  computed: {
      player() {
        return this.$refs.videoPlayer.player
      },
      ...mapStores(store)
    },
    destroyed() {
      window.removeEventListener("resize", this.sizeCorrect);
    },
    methods: {
      handleScroll () {
        this.scrolling = true
      },
      endScroll(){
        this.scrolling = false
      },
      sizeCorrect(){
        if(window.innerHeight > window.innerWidth){
          this.chatResponsive = true
        }else{
          this.chatResponsive = false
        }

        this.strWidth = window.innerWidth * 0.2
        
        if(window.innerHeight > window.innerWidth){
          this.strWidth = window.innerWidth * 0.5
        }
        this.strHeight = this.strWidth/(16/9)
        this.maxW = window.innerWidth * 0.5,
        this.maxH = window.innerHeight * 0.5,
        this.minW = window.innerWidth * 0.15,
        this.minH = window.innerHeight * 0.175,

        this.chatWidth = window.innerWidth * 0.2
        this.chatMinWidth = window.innerWidth * 0.175
        this.chatMaxWidth = window.innerWidth * 0.25
       
        
        this.chatH = window.innerHeight
        if(window.innerHeight > window.innerWidth){
          this.chatResponsive = true
          this.nochatSize = 100
        }else{
          this.chatResponsive = false
          this.nochatSize = ((window.innerWidth-this.chatWidth)/window.innerWidth)*100
        }
        if(window.innerHeight < window.innerWidth && this.chatActive==false){
          console.log("xDDDDD")
          this.nochatSize = 100
        }
        this.chatL = window.innerWidth * (this.nochatSize/100)
        
      },
      onPlayerPlay(player) {
        if(this.admins.includes(this.user)){
          this.$socket.emit('clickPlay')
          this.$socket.emit('timeUpdate',player.currentTime())
          this.$socket.emit('play')
        }
      },

      onPlayerPlaying(){
       
      },

      onPlayerPause(player) {
        if(this.admins.includes(this.user)){
          console.log('player pause!', player)
          this.$socket.emit('timeUpdate',player.currentTime())
          this.$socket.emit('pause')
        }else{
          this.current = null
        }
      },
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState)
      },
      playerReadied(player) {
        //console.log('the player is readied', player)

        //console.log(this.movies)

        if(!this.admins.includes(this.user)){
          this.$refs.videoPlayer.player.currentTime(this.current)
          this.$refs.videoPlayer.player.controlBar.progressControl.disable()
          this.$refs.videoPlayer.player.controlBar.playToggle.disable()
 
        }else{
          let skip = this.$refs.videoPlayer.player.controlBar.addChild('button', {})
          let skipEl = skip.el()
         
          skipEl.innerHTML = '<span>>>></span>'
          skipEl.onclick = ()=>{
            this.$refs.videoPlayer.player.currentTime(this.$refs.videoPlayer.player.duration())
          }
        }

        this.$refs.videoPlayer.player.controlBar.progressControl.on('click', ()=>{
          if(this.admins.includes(this.user)){
          
            console.log(player.currentTime())
            this.$socket.emit('barClick',player.currentTime())
        
          }
        })
        this.$refs.videoPlayer.player.controlBar.on('mouseover', ()=>{
      
          this.overControl = true
          this.blindHeight = 50
          console.log(this.blindHeight)
          
        })
        this.$refs.videoPlayer.player.controlBar.on('mouseleave', ()=>{
          
          this.overControl = false
          this.blindHeight = 97
          console.log(this.blindHeight)
          
        })
        
        //var result = Object.entries(this.movies);

        //this.$socket.emit('updatequeue')
        this.$refs.videoPlayer.player.playlist(this.$refs.videoPlayer.player.updateSrc(this.movies))
        console.log(this.$refs.videoPlayer.player.playlist())


        
      },

      onPlayerTimeupdate(player){
        
        if(this.admins.includes(this.user) && Math.floor(player.currentTime()) % 2 == 0 && Math.floor(player.currentTime())!=this.timeHelp){
            this.timeHelp = Math.floor(player.currentTime())
            this.$socket.emit('updateondelay', {time: player.currentTime(), canPause: this.$refs.videoPlayer.player.paused()})
            
        }/*else{
          
            if(Math.floor(player.currentTime()) % 3 == 0 && Math.floor(player.currentTime())!=this.timeHelp){
              this.timeHelp = Math.floor(player.currentTime())
              //console.log(Math.floor(player.currentTime()))
              //console.log('changed user')
              //this.$socket.emit('updateondelay', player.currentTime())
              if((data - currentTime) < -3||(data - currentTime) > 3){
              


              }
            }
         
        }
        */
      },

      onPlayerEnded(player){

        console.log('Koniec filmu')
        console.log(player)
        if(this.admins.includes(this.user)){
        this.$socket.emit('ended')
        }
        
      },
      

      async TwitchLogin(){

        window.location.href = process.env.VUE_APP_REDIRECT;

        VideoService.TwitchLogin();
      },

      async addMovie(link360,link480,link720,link1080){

      this.isReady=true

      const movie = {
        thumbnail: this.movieThumb,
        title: this.movieTitle,
        movies: []
      }

      if(link360.length>0){
        movie.movies.push(
          {
          src: link360,
          type: 'video/'+this.selected,
          label: '360'
        }
        )
      }
      if(link480.length>0){
        movie.movies.push(
          {
          src: link480,
          type: 'video/'+this.selected,
          label: '480'
        }
        )
      }
      if(link720.length>0){
        movie.movies.push(
          {
          src: link720,
          type: 'video/'+this.selected,
          label: '720'
        }
        )
      }
      if(link1080.length>0){
        movie.movies.push(
          {
          src: link1080,
          type: 'video/'+this.selected,
          label: '1080'
        }
        )
      }
        
        await VideoService.addMovie(movie)
        
        if(this.movies.length==0){
          await this.$socket.emit('updatequeue')
        }
        this.$socket.emit('getQueue')

        this.link360 = ""
        this.link480 = ""
        this.link720 = ""
        this.link1080 = ""
        this.movieTitle = ""
        this.movieThumb = ""

      },

      async clearQueue(){

        this.isReady=false
        this.$socket.emit('clear')
        
      },

      async deleteOne(mv){
        console.log(mv)
        this.$socket.emit('clearOne', mv)
      },

      logout(){

        if(this.admins.includes(this.user)){
          this.user = null
          this.$socket.emit('pause')
        }
        this.canPause = false
        localStorage.clear()
        this.user = null
        this.token = null
        this.tokenStore.token = null
        this.$refs.videoPlayer.player.controlBar.progressControl.disable()
        this.$refs.videoPlayer.player.controlBar.playToggle.disable()

      },
      hideStream(){
        this.streamActive = !this.streamActive
        this.$cookies.set("sActive", this.streamActive)
        console.log(this.$cookies.get("sActive"))
      },
      hideChat(){
        this.chatActive = !this.chatActive
        if(this.chatActive==true){
          this.sizeCorrect()
        }else{
          this.nochatSize = 100
        }
        this.$cookies.set("cActive", this.chatActive)
      },
      eHandler(data) {

        if(data.width!=this.strWidth){
          this.strWidth = data.width
          this.strHeight = data.width/(16/9)
        }else if(data.height!=this.strHeight){
          this.strWidth = data.height*(16/9)
          this.strHeight = data.height
        }
        this.left = data.left
        this.top = data.top

        this.focused = false
        
      },
      eHandler2(data) {

        if(data.width!=this.strWidth){
          this.strWidth = data.width
          this.strHeight = data.width/(16/9)
        }else if(data.height!=this.strHeight){
          this.strWidth = data.height*(16/9)
          this.strHeight = data.height
        }

        this.left = data.left
        this.top = data.top

        this.focused = true
        
      },
      chatResize(data){

        this.chatWidth = data.width

        console.log("To to: " + this.chatWidth)

        this.chatL = data.left
        
        this.nochatSize = ((window.innerWidth-this.chatWidth)/window.innerWidth)*100

        this.focused = false
        
      },
      chatResize2(data){

        this.chatWidth = data.width
        
        this.nochatSize = ((window.innerWidth-this.chatWidth)/window.innerWidth)*100

        this.focused = true

      },
      ytPlay(){

        
        if(this.admins.includes(this.user)){
          if(this.$refs.videoPlayer.player.paused()){
            this.$refs.videoPlayer.player.play()
          }else{
            this.$refs.videoPlayer.player.pause()
          }
        }else if(this.canPause){
          if(this.$refs.videoPlayer.player.paused()){
            this.$refs.videoPlayer.player.play()
          }else{
            this.$refs.videoPlayer.player.pause()
          }
        }

      }
   

   
      
    },
    mounted() {
      
      
    },
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*@import url('https://unpkg.com/@videojs/themes@1/dist/forest/index.css');*/
@import url('https://cdn.jsdelivr.net/npm/videojs-resolution-switcher-vjs7@1.0.0/videojs-resolution-switcher.css');
:root {
    color-scheme: light dark;
}
.container{

  width: 100%;
  padding-left: 0;
  background-color: black;
  margin-left: 0;
  height: 100vh;
  
}
.Queue-col{
  margin-left: 8%;
  margin-right: 8%;
  height: 42vh;
  max-height: 42vh;
  min-width: 500px;
  max-width: 100%;
}
.scrolling{

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: red;

}
.icon-trash {
  width: 48px;
  height: 48px;
  position: absolute;
  overflow: hidden;
  margin-left: 25px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-trash .trash-lid {
  width: 62%;
  height: 10%;
  position: absolute;
  left: 50%;
  margin-left: -31%;
  top: 10.5%;
  background-color: #000;
  border-top-left-radius: 80%;
  border-top-right-radius: 80%;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
  background-color: #E5E9EA
}

.icon-trash .trash-lid:after {
  content: "";
  width: 26%;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -13%;
  margin-top: -10%;
  background-color: inherit;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  transform: rotate(-1deg); 
}

.icon-trash .trash-container {
  width: 56%;
  height: 65%;
  position: absolute;
  left: 50%;
  margin-left: -28%;
  bottom: 10%;
  background-color: #000;
  border-bottom-left-radius: 15%;
  border-bottom-right-radius: 15%;
  background-color: #E5E9EA
}

.icon-trash .trash-container:after {
  content: "";
  width: 110%;
  height: 12%;
  position: absolute;
  left: 50%;
  margin-left: -55%;
  top: 0;
  background-color: inherit;
  border-bottom-left-radius: 45%;
  border-bottom-right-radius: 45%;
}

.icon-trash .trash-line-1 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 38%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #252527;
}

.icon-trash .trash-line-2 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 50%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #252527;
}

.icon-trash .trash-line-3 {
  width: 4%;
  height: 50%;
  position: absolute;
  left: 62%;
  margin-left: -2%;
  bottom: 17%;
  background-color: #252527;
}
.yt{

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 97vh;
  z-index: 1;
  background-color: transparent;

}

#twitch-chat-embed{

  visibility: visible;


 
}
.TwitchChat-container{

  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  visibility: hidden;
  
  
  
}

.chatResponsive{

  position: relative;
  height: 70vh;
  width: 100%;
  padding: 0;
  z-index: 1;

}
.TwitchStream-container{

  position: fixed;
  top: 0;
  background-color: black;
  height: 100%;
  visibility: hidden;
  z-index: 1;
  
}
.TwitchStream-container:hover{
  
  border-color: blue;
  
}

.TwitchStream{

  overflow: hidden;
  outline-style: solid;
  outline-width: 0px;
  border-color: transparent;
  visibility: visible;
  

}
.TwitchChat{

  visibility: visible;
  outline-style: solid;
  outline-width: 0px;
  background-color: black;
  
}

.TwitchChat:hover{
  outline-width: 3px;
  outline-color: green;
}

.queueList{

  width: 100%;
  
  
  
  margin-left: auto;
  margin-right: auto;

}

.TwitchStream:hover{

  outline-color: green;
  outline-width: 3px;
}
.video-player-box{
  width: 100%;
  height: 100vh;
}

.options{
  margin-left: 0;
  width: 100%;
  background: linear-gradient(to bottom,#21262d 0%, #161b22 50%, #0d1117 95%, #0d1117 100%);
  height: 60vh;
  min-height: 600px;
}
.aq{
  margin-top: 3%;
}
.queueItem{

  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;

}

.addMovie, .addMovieTitle, .addMovieThumb{

  margin-top: 2%;
  padding: 6px 12px;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  border-radius: 4px;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
  height: 40px;
  appearance: none;
  transition: border 0.15s ease 0s;
  
}
.addMovieTitle{
  margin-top: 5%;  
}

.empty{

  background-color: black;
  position: relative;
}
.empty-text{
  position: absolute;
  font-size: 2vw;
  color: white;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.drag-container-1,
.drag-container-2 {
  
  position: absolute;
  top: 0;
  right: 0;
  width: 11%;
  height: 20%;
  background: url("../assets/move_icon.webp");
  background-color: rgba(255, 255, 255, 0.8);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  color: white;
  text-align: center;
  cursor: grab;
}
.drag-container-2:hover{
  opacity: 1;
}

.resize-container{
  position: absolute;
  background: transparent;
  top: 0;
  width: 100%;
  height: 100%;
}
.scroll-area {
  position: absolute;
  top: 0;
  margin: auto;
  height: 100vh;
  width: 100%;
}
.ttv-logo{
  width: 5vw;
  
}
.b-login{
  width: 10vw;
  margin-left: 9%;
}
.b-logout{
  width: 10vw;
  margin-left: 5%;
}
.b-hidestream, .b-hidechat, .b-add, .b-clear, .b-logout, .b-login{
  display: inline-block;
  outline: 0;
  cursor: pointer;
  text-align: center;
  border: 0;
  padding: 7px 16px;
  min-height: 40px;
  min-width: 50px;
  color: #ffffff;
  background: #570080;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset;
}
.b-hidestream:hover, .b-hidechat:hover, .b-add:hover, .b-clear:hover, .b-login:hover, .b-logout:hover{
  background: #4c046d;
}

.s-add{
   margin-top: 3%;
   margin-left: 2%;
   width: 10vw;
   padding: 6px 12px;
   background: rgb(31, 32, 35);
   border: 1px solid rgb(60, 63, 68);
   border-radius: 4px;
   font-size: 0.8rem;
   color: rgb(247, 248, 248);
   height: 40px;
   appearance: none;
   transition: border 0.15s ease 0s;
}
.add-form{
  text-align: left;
  margin-left: 25%;
}
.options-row{
  padding-top: 2%;
  
}
.options-col{
  display: flex;
  flex-direction: column;

}

.queueTitle{
  position: absolute;
  font-size: 1.1rem;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  color: white;
  
}
.queueGroup{
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
  margin-right: 1%;
  height: 10vh;
  background: #43005c;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset;
  color: black;
}
.queueGroup:hover{
  background-color: rgba(102, 2, 252, 0.8);
}
.queueThumb{
  position: absolute;
  top: 0%;
  left: 0%;
  width: 18%;
  height: 100%;
  
}
.scrollQueue{
  height: 41vh;
  background: rgb(31, 32, 35);
  border: 1px solid rgb(60, 63, 68);
  appearance: none;
  transition: border 0.15s ease 0s;
  border-radius: 4px;
  min-height: 300px;
  
}
.footer{
  width: 100%;
  background-color: black;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: left;
  row-gap: 0px;

}
.logos{
  width: 1.8rem;
  height: 1.8rem; 
}
.a-link{
  width: 1.8rem;
  text-align: center;
 
}
.madeby{
  color: white;
  line-height: 3vh;
  font-size: 0.8rem;
  margin-left: 1%;
}
.gotolink{
  
  width: 100%;
  height: 100%;
  font-size: 2rem;
  background-color: red;
  
}

@media screen and (max-width:767px) {



}

@media screen and (min-width:768px) and (max-width:1024px){

  .video-player-box{
    
    height: 75vh;
  }
  .b-logout{
    width: 98.5%;
    margin-left: 0;
  }
  .col-6{
    width: 100%;
  }
  .addMovie{
    width: 100%;
  }
  .s-add{
    width: 48%;
  }
  .b-add{
    width: 50%;
  }
  .add-form{
    margin-left: 0;
    margin-right: 4%;
  }
  .Queue-col{
    margin-left: 0;
    margin-right: 0;
    padding-right: 4%;
  }
  .col-1{
    width: 0%;
  }
  .options{
    height: 100%;
  }
  .logos{
    width: 1.5rem;
    height: 1.5rem; 
    margin-right: 5%;
  }
  .a-link{
    width: 1.5rem;
  }

}

@media screen and (min-width:1024px) and (max-width:1515px){

  .b-hidestream, .b-hidechat, .b-add, .b-clear, .b-logout, .b-login{
    font-size: 0.7rem;
    min-height: 35px;
  }
  .addMovie, .addMovieTitle, .addMovieThumb{
    font-size: 0.7rem;
    height: 35px;
  }
  .s-add{
    font-size: 0.7rem;
    height: 35px;
  }
  .queueTitle{
    font-size: 0.8rem;
  }
  .queueThumb{
    width: auto;
  }
  .icon-trash{
    margin-left: 0;
  }
  .Queue-col{
    margin-left: 2%;
    margin-right: 2%;
  }
  .add-form{
    margin-left: 5%;
  }
  .col-1{
    width: 0%;
  }
  .col-2{
    width: 35%;
  }
}
@media (orientation: portrait) {

  .video-player-box{
    
    height: 75vh;
  }
  .b-logout{
    width: 98.5%;
    margin-left: 0;
  }
  .col-6{
    width: 100%;
  }
  .addMovie{
    width: 100%;
  }
  .s-add{
    width: 48%;
  }
  .b-add{
    width: 50%;
  }
  .add-form{
    margin-left: 0;
    margin-right: 4%;
  }
  .Queue-col{
    margin-left: 0;
    margin-right: 0;
    padding-right: 4%;
  }
  .col-1{
    width: 0%;
  }
  .options{
    height: 100%;
  }
  .logos{
    width: 1.5rem;
    height: 1.5rem; 
    margin-right: 5%;
  }
  .a-link{
    width: 1.5rem;
  }
}
</style>
